import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { FirstContentEntity } from 'client/data/models/editorial-review';
import { buildMmysMsrpPricesPath, VehicleModel } from 'client/data/models/vehicle-v2';
import { MENU_HASH } from 'site-modules/core-page/utils/constants';
import { SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { getPriceRangeString } from 'site-modules/shared/utils/price-utils';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { ScrollLink } from 'site-modules/shared/components/scroll-link/scroll-link';

const CREATIVE_ID = 'msrp_range';

export function trackTooltipOpen() {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_CONTENT,
      subaction_name: TrackingConstant.VIEW_TOOLTIP,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: CREATIVE_ID,
      value: 'MSRP Range',
    },
  });
}

function VehicleInfoUI({ className, msrpPriceRange, firstContent, isPreprod, isMobile }) {
  const minPrice = get(msrpPriceRange, `[${get(msrpPriceRange, 'length', 0) - 1}]`);
  const maxPrice = get(msrpPriceRange, '[0]');
  const hasPrice = !!(minPrice && maxPrice);
  const vehiclePrice = hasPrice ? getPriceRangeString(minPrice, maxPrice) : '$';

  const availableDate = !!firstContent && get(firstContent, 'releaseDate.value');
  const estimatedPrice = !!firstContent && get(firstContent, 'price.value');

  return (
    <div className={classnames('intro-vehicle-info', className)}>
      {isPreprod && availableDate && (
        <div className="pr-md-1">
          Release Date:&nbsp;
          <strong className="d-inline-block size-20">{availableDate}</strong>
        </div>
      )}
      {hasPrice && (
        <div className="pt-0_5 pb-md-0_5 pr-md-1" data-tracking-parent={CREATIVE_ID}>
          {isMobile ? 'Price:' : 'Price Range:'}&nbsp;
          <strong className="d-inline-block size-20">
            {isMobile ? (
              <ScrollLink
                to={MENU_HASH.TRIM_SUMMARY_MOBILE}
                scrollConfig={{ offset: SCROLL_OFFSET }}
                data-tracking-id="view_pricing_content"
                data-tracking-value="MSRP Range"
              >
                {vehiclePrice}
              </ScrollLink>
            ) : (
              vehiclePrice
            )}
            &nbsp;
            <TooltipItem
              id="msrp-range-tooltip"
              className="top-0"
              iconClassName="medium"
              innerClassName="p-0_75"
              item={{
                placement: 'bottom',
                text: 'Price range reflects Base MSRP for various trim levels, not including options or fees.',
              }}
              onTooltipOpen={trackTooltipOpen}
            />
          </strong>
        </div>
      )}
      {!hasPrice && !!estimatedPrice && (
        <div className="pt-0_5 pb-md-0_5 pr-md-1">
          Estimated Price:&nbsp;
          <strong className="d-inline-block size-20">{estimatedPrice}</strong>
        </div>
      )}
    </div>
  );
}

VehicleInfoUI.propTypes = {
  msrpPriceRange: PropTypes.arrayOf(PropTypes.number),
  firstContent: FirstContentEntity,
  className: PropTypes.string,
  isPreprod: PropTypes.bool,
  isMobile: PropTypes.bool,
};

VehicleInfoUI.defaultProps = {
  msrpPriceRange: null,
  firstContent: null,
  className: null,
  isPreprod: false,
  isMobile: false,
};

export const VehicleInfo = connectToModel(VehicleInfoUI, {
  msrpPriceRange: bindToPath(({ vehicle }) => buildMmysMsrpPricesPath(getParamsFromVehicle(vehicle)), VehicleModel),
});
