import { throttle, without } from 'lodash';

export const scrollSpy = {
  spyCallbacks: [],

  mount() {
    document.addEventListener('scroll', scrollSpy.eventHandler);
  },

  unmount() {
    document.removeEventListener('scroll', scrollSpy.eventHandler);
  },

  eventHandler: throttle(() => {
    scrollSpy.spyCallbacks.forEach(callback => callback());
  }, 50),

  addSpy(callback) {
    if (!scrollSpy.spyCallbacks.length) {
      scrollSpy.mount();
    }

    scrollSpy.spyCallbacks.push(callback);
    callback();
  },

  removeSpy(callback) {
    scrollSpy.spyCallbacks = without(scrollSpy.spyCallbacks, callback);

    if (!scrollSpy.spyCallbacks.length) {
      scrollSpy.unmount();
    }
  },
};
