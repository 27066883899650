import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { scroller, Events } from 'react-scroll';
import { omit, noop, get } from 'lodash';
import debounce from 'lodash/debounce';
import { SCROLL_DURATION } from 'site-modules/shared/constants/sub-navigation';
import { scrollSpy } from './scroll-spy';

const DEFAULT_SCROLL_CONFIG = {
  duration: SCROLL_DURATION,
  offset: 0,
  smooth: true,
  isDynamic: true,
};

/**
 * This component was created because the 'react-scroll' Link component is problematic for keyboard accessibility.
 * This component works in combination with ScrollElement (a replacement for the 'react-scroll' Element component)
 * to manage focus and support keyboard accessibility.
 */
export class ScrollLink extends Component {
  static propTypes = {
    // id of target element
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    spy: PropTypes.bool,
    onSetActive: PropTypes.func,
    onSetInactive: PropTypes.func,
    onClick: PropTypes.func,
    scrollConfig: PropTypes.shape({
      duration: PropTypes.number,
      offset: PropTypes.number,
      smooth: PropTypes.bool,
      isDynamic: PropTypes.bool,
      containerId: PropTypes.string,
    }),
    /**
     * noScroll={true} is helpful for scenarios where you want to move focus to a sticky element.  usually
     * sticky elements are below the footer or lower down in the page source, so it is not necessary to
     * scroll the page when you move focus to this element.  A good example is the sticky "Compare tray" that
     * we use on some pages when we are selecting multiple vehicles to compare (ex: https://www.edmunds.com/sedan/).
     */
    noScroll: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    scrollConfig: null,
    onSetActive: noop,
    onSetInactive: noop,
    onClick: noop,
    spy: false,
    noScroll: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  componentDidMount() {
    if (this.props.spy) {
      scrollSpy.addSpy(this.handleSpy);
    }
  }

  componentWillUnmount() {
    if (this.props.spy) {
      scrollSpy.removeSpy(this.handleSpy);
    }
  }

  getTargetElement = () => {
    const { to } = this.props;

    // TODO: Remove getElementsByName once all <Element>s are replaced with ScrollElement
    return document.getElementById(to) || document.getElementsByName(to)[0];
  };

  handleScrollEnd = () => {
    Events.scrollEvent.remove('end');
    const targetElement = this.getTargetElement();

    if (!targetElement) {
      return;
    }

    const scrollPosition = window.pageYOffset;
    targetElement.focus();
    // This ensures that the window's scroll position remains the same before and after the focus change.
    window.scrollTo(0, scrollPosition);
  };

  handleSpy = debounce(() => {
    const { to, onSetActive, onSetInactive, scrollConfig } = this.props;
    const { isActive } = this.state;
    const targetElement = this.getTargetElement();

    if (!targetElement) {
      return;
    }

    const { top, bottom } = targetElement.getBoundingClientRect();
    const offset = -get(scrollConfig, 'offset', DEFAULT_SCROLL_CONFIG.offset) + 1;
    const isActiveNow = top <= offset && bottom >= offset;

    if (isActiveNow && !isActive) {
      onSetActive(to);
    }

    if (!isActiveNow && isActive) {
      onSetInactive(to);
    }

    this.setState({ isActive: isActiveNow });
  }, 0);

  scrollToHref = () => {
    const { scrollConfig, to, noScroll } = this.props;
    const targetElement = this.getTargetElement();

    if (!targetElement) {
      return;
    }

    if (noScroll) {
      targetElement.focus();
    } else {
      Events.scrollEvent.register('end', this.handleScrollEnd);
      scroller.scrollTo(to, { ...DEFAULT_SCROLL_CONFIG, ...scrollConfig });
    }
  };

  handleClick = event => {
    event.preventDefault();
    this.scrollToHref();
    this.props.onClick(event);
  };

  render() {
    const { children, to, className, activeClass, ...otherProps } = this.props;
    const { isActive } = this.state;
    const linkProps = omit(otherProps, ['spy', 'onSetActive', 'onSetInactive', 'onClick', 'scrollConfig', 'noScroll']);

    return (
      <a
        href={`#${to}`}
        className={classnames(className, { [activeClass]: isActive })}
        {...linkProps}
        onClick={this.handleClick}
        aria-current={isActive ? 'true' : undefined}
      >
        {children}
      </a>
    );
  }
}
