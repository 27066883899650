import { nth, compose, head } from 'lodash/fp';
import { matchString } from 'client/utils/compose';

const UNPAIRED_TAGS = ['input', 'hr', 'br', 'img'];

const getTagName = compose(
  nth(1),
  matchString(/<([/a-z]+)\b[^>]*>/) // Get the first group ([/a-z]+)
);

export function isHTMLValid(content) {
  if (typeof content !== 'string') {
    return false;
  }

  /**
   * Content starts with '<' and ends with '>'
   * */
  if (!content.startsWith('<') && !content.endsWith('>')) {
    return false;
  }

  /**
   * .match returns an array or null
   * */
  const tags = content.match(/<("[^"]*"|'[^']*'|[^'">])*>/g);

  /**
   * If there aren't any tags, content is invalid.
   * */
  if (tags === null) {
    return false;
  }

  return !tags
    .map(getTagName)
    .filter(tagName => tagName && !UNPAIRED_TAGS.includes(tagName))
    .reduce((stack, tagName) => {
      if (head(tagName) !== '/') {
        // opening tags case
        stack.push(tagName);
      } else if (tagName.slice(1) === stack[stack.length - 1]) {
        // closing tags case
        stack.pop();
      }
      return stack;
    }, []).length; // Validating length
}
