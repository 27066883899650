import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, noop } from 'lodash';
import debounce from 'lodash/debounce';

import './sticky-to-bottom.scss';

const DEBOUNCE_TIME = 100;

export function StickyToBottom({ children, contentWrapperClassName, enabled, offsetSelector, additionalOffset }) {
  const [viewportHeight, setViewportHeight] = useState(0);
  const [elementHeight, setElementHeight] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const stickyContentRef = useRef();

  const getElementHeight = debounce(() => {
    setElementHeight(get(stickyContentRef, 'current.offsetHeight', 0));
  }, DEBOUNCE_TIME);

  const getViewportHeight = debounce(() => {
    setViewportHeight(window.innerHeight);
  }, DEBOUNCE_TIME);

  useEffect(() => {
    if (!enabled) {
      return noop;
    }

    setViewportHeight(window.innerHeight);
    setElementHeight(get(stickyContentRef, 'current.offsetHeight', 0));
    setOffsetHeight(get(document.querySelector(offsetSelector), 'offsetHeight', 0) + additionalOffset);

    window.addEventListener('resize', getViewportHeight);

    return () => {
      window.removeEventListener('resize', getViewportHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!enabled) {
    return children({});
  }

  const isInViewport = viewportHeight - offsetHeight - elementHeight >= 0;

  return (
    <div className={classnames('sticky-to-bottom h-100 d-flex flex-column', { 'in-viewport': isInViewport })}>
      <div
        ref={stickyContentRef}
        className={classnames('sticky-content-wrapper', contentWrapperClassName)}
        style={isInViewport ? { top: offsetHeight } : undefined}
      >
        {children({ onHeightUpdate: getElementHeight })}
      </div>
    </div>
  );
}

StickyToBottom.propTypes = {
  children: PropTypes.func.isRequired,
  offsetSelector: PropTypes.string,
  additionalOffset: PropTypes.number,
  contentWrapperClassName: PropTypes.string,
  enabled: PropTypes.bool,
};

StickyToBottom.defaultProps = {
  offsetSelector: null,
  contentWrapperClassName: null,
  additionalOffset: 0,
  enabled: true,
};
