import React from 'react';

const EDMUNDS_LOGO_NO_TEXT_PATH = (fillColor, otherProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    fill={fillColor}
    d="M62.2 59.5c-1.3 0.2-2.5 0.4-3.7 0.4 -1.2 0-2.5-0.2-3.7-0.4 -9-1.7-16-7.9-19-16 4.4 4.9 13 7.4 22.8 7.5 9.8-0.1 18.4-2.6 22.8-7.5C78.2 51.6 71.2 57.8 62.2 59.5L62.2 59.5zM28.2 17.5v5.7c0 0.6 0.2 1.2 0.6 1.8 -3.4 0.3-6.7 0.8-9.8 1.4 1.6-4.2 5.1-10.8 9.2-15 0.7-0.7 3.6-3.2 7.8-3.9C38.1 7.1 45.5 6 57 6h1.6 1.6c11.5 0 18.9 1.1 21 1.4 4.2 0.7 7.1 3.2 7.8 3.9 4.1 4.2 7.6 10.9 9.2 15 -6.5-1.4-14-1.9-22-2.1 -0.9 0-3.3-0.1-5.5-0.1h-2.2c-1.7 0-3.3 0-5 0h-4.9 -4.9c-1.7 0-3.4 0-5 0h-1.4c0.1-0.3 0.2-0.6 0.2-1v-5.7c0-1.9-2-3.5-4.4-3.5H32.6C30.2 14 28.2 15.5 28.2 17.5L28.2 17.5zM93.6 54.2c-3.4-0.3-6.1-3-6.4-6.4 -0.5-4.5 3.4-8.3 7.9-7.8 3.4 0.3 6.1 3 6.4 6.4C102 50.9 98.2 54.6 93.6 54.2L93.6 54.2zM22 54.2c-3.4-0.3-6.1-3-6.4-6.4 -0.5-4.5 3.4-8.3 7.9-7.8 3.4 0.3 6.1 3 6.4 6.4C30.4 50.9 26.6 54.6 22 54.2L22 54.2zM116.9 22.2c0-1.7-1.5-2.9-3.2-2.9l-6.7 0.2c-1.5 0-2.7 1.2-2.9 2.6 0 0.3-0.1 0.7-0.2 1l-0.8-1.5c-2.7-5-6.5-11-10.2-14.8h0c0 0-3.3-4-9.9-5.3 0 0 0 0 0 0C77.2 0.3 67.8-0.1 58.6 0 49.4-0.1 40 0.3 34.2 1.5c0 0 0 0 0 0 -6.6 1.3-9.9 5.3-9.9 5.3h0c-3.8 3.8-7.5 9.8-10.2 14.8l-0.8 1.7c-0.1-0.4-0.2-0.8-0.3-1.2 -0.2-1.4-1.4-2.6-2.9-2.6l-6.7-0.2c-1.7 0-3.1 1.2-3.2 2.9L0 25.5c0 1.7 1.3 3.1 2.9 3.1l7.1 0.8 0.1-0.1c-0.1 0.1-0.1 0.2-0.1 0.3 -2.4 2.7-3.8 5.8-4.2 8.3C5 40.5 4.6 43.8 4.6 48.1c0 6.1 1.7 14.4 2.1 18.4v6c0 3 2.5 5.4 5.5 5.4h7.5c3 0 5.5-2.4 5.5-5.4v-4.5c7.8-0.7 20.6-1 33.4-1 12.8 0 25.6 0.3 33.4 1v4.5c0 3 2.5 5.4 5.5 5.4h7.5c3 0 5.5-2.4 5.5-5.4v-6c0.4-4 2.1-12.4 2.1-18.4 0-9.4-3.5-16.1-5.4-18.5 0-0.1-0.1-0.1-0.1-0.2l7-0.8c1.7 0 3-1.5 2.9-3.1L116.9 22.2zM84.5 10h-11C71 10 69 11.7 69 13.7v6.1c0 0.4 0 0.8 0.2 1.2 2.3-2.8 5.9-4.7 9.8-4.7 2.9 0 7.5 1.8 9.8 4.6 0.1-0.3 0.2-0.7 0.2-1.1v-6.1C89 11.7 87 10 84.5 10"
    {...otherProps}
  />
);

export const EDMUNDS_LOGO_NO_TEXT = ({
  width = 117,
  fillColor = '#007EE5',
  classes = '',
  ariaHidden = true,
  ...otherProps
} = {}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 117 78"
    width={width}
    className={classes}
    aria-hidden={ariaHidden}
  >
    {EDMUNDS_LOGO_NO_TEXT_PATH(fillColor, otherProps)}
  </svg>
);

export const EDMUNDS_LOGO_NO_TEXT_GRADIENT = ({
  width = 117,
  fillColor = '#007EE5',
  classes = '',
  ...otherProps
} = {}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117 78" width={width} className={classes}>
    <defs>
      <linearGradient id="gradient-blue" x1="-13.05%" y1="0%" x2="112.71%" y2="0%">
        <stop offset="0%" style={{ stopColor: '#154A99', stopOpacity: '1' }} />
        <stop offset="100%" style={{ stopColor: '#16C7DF', stopOpacity: '1' }} />
      </linearGradient>
    </defs>
    {EDMUNDS_LOGO_NO_TEXT_PATH(fillColor, otherProps)}
  </svg>
);

export const edmundsLogoHalfCarLeft = ({ className = '', width = 63, fill = '#007EE5' } = {}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 78" width={width} className={className}>
    <path
      fill={fill}
      d="M62.8 59.4c-0.2 0-0.4 0.1-0.6 0.1 -1.3 0.2-2.5 0.4-3.7 0.4s-2.5-0.2-3.7-0.4c-9-1.7-16-7.9-19-16 4.4 4.9 13 7.4 22.8 7.5 1.4 0 2.9-0.1 4.2-0.2V24.1h-4.2 -4.9c-1.7 0-3.4 0-5 0h-1.4c0.1-0.3 0.2-0.6 0.2-1v-5.7c0-1.9-2-3.5-4.4-3.5H32.6c-2.4 0.1-4.4 1.6-4.4 3.6v5.7c0 0.6 0.2 1.2 0.6 1.8 -3.4 0.3-6.7 0.8-9.8 1.4 1.6-4.2 5.1-10.8 9.2-15 0.7-0.7 3.6-3.2 7.8-3.9C38.1 7.1 45.5 6 57 6h1.6 1.6c0.9 0 1.8 0 2.6 0V0c-1.4 0-2.8 0-4.2 0C49.4-0.1 40 0.3 34.2 1.5c-6.6 1.3-9.9 5.3-9.9 5.3 -3.8 3.8-7.5 9.8-10.2 14.8l-0.8 1.7c-0.1-0.4-0.2-0.8-0.3-1.2 -0.2-1.4-1.4-2.6-2.9-2.6l-6.7-0.2c-1.7 0-3.1 1.2-3.2 2.9L0 25.5c0 1.7 1.3 3.1 2.9 3.1l7.1 0.8 0.1-0.1C10 29.4 10 29.5 10 29.6c-2.4 2.7-3.8 5.8-4.2 8.3C5 40.5 4.6 43.8 4.6 48.1c0 6.1 1.7 14.4 2.1 18.4v6c0 3 2.5 5.4 5.5 5.4h7.5c3 0 5.5-2.4 5.5-5.4V68c7.8-0.7 20.6-1 33.4-1 1.4 0 2.8 0 4.2 0V59.4zM22 54.2c-3.4-0.3-6.1-3-6.4-6.4 -0.5-4.5 3.4-8.3 7.9-7.8 3.4 0.3 6.1 3 6.4 6.4C30.4 50.9 26.6 54.6 22 54.2z"
    />
  </svg>
);
