import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { get } from 'lodash';
import Button from 'reactstrap/lib/Button';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildPreProdContentPath, EditorialReviewModel, FirstContentEntity } from 'client/data/models/editorial-review';
import { VisitorModel } from 'client/data/models/visitor';
import { buildPopularStylesPath, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { getUnifiedLeadFormDealerCountV2Path, UnifiedLeadFormModel } from 'client/data/models/unified-lead-form';
import { COLLAPSE_STATE, THANK_YOU_VIEWS } from 'site-modules/shared/constants/lead-form/lead-form-params';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
import { LEAD_FORM_CONFIGS } from 'site-modules/shared/constants/lead-form/lead-form-configs';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { getLeadSubmissionInfoWithoutEmail } from 'site-modules/shared/components/lead-form/utils/utils';
import { getLeadFormCreativeId } from 'site-modules/shared/components/lead-form/utils/lead-form-creative-id';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { TransparentPricingLeadFormDrawer } from 'site-modules/shared/components/lead-form/transparent-pricing-lead-form/transparent-pricing-lead-form-drawer';
import { Link } from 'site-modules/shared/components/link/link';

import './preprod-pricing-button.scss';

const LEAD_FORM_COPY = 'Contact your local dealers about upcoming availability and pricing details.';

function PreprodPricingButtonUI({
  firstContent,
  vehicle,
  mostPopularStyleId,
  zipCode,
  isMobile,
  leadFormDealerCount,
  className,
  copyClassName,
  btnClassName,
  pageName,
  hasSrpLink,
}) {
  const {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    year,
  } = vehicle;
  const [isDrawerOpened, toggleDrawerOpened] = useToggle(false);

  const getLeadSubmissionInfoWithComments = useCallback(
    params => {
      const submissionInfo = getLeadSubmissionInfoWithoutEmail(params);

      return {
        ...submissionInfo,
        leads: submissionInfo.leads.map(lead => ({
          ...lead,
          comments: `I'm interested in the ${year} ${makeName} ${modelName} once it becomes available.`,
        })),
      };
    },
    [makeName, modelName, year]
  );

  if (leadFormDealerCount === null) {
    return null;
  }

  const srpLinkComponent = !!hasSrpLink && (
    <Button
      tag={Link}
      to={srpLinkBuilder({ make: makeSlug, model: modelSlug })}
      color="outline-blue-50"
      size="lg"
      className="cta-button text-transform-none w-100 py-0_75"
      data-tracking-id="trim_summary_srp_button"
      rel="nofollow"
    >
      Other years for sale
    </Button>
  );

  if (!leadFormDealerCount) {
    return srpLinkComponent;
  }

  const dataTracking = getLeadFormCreativeId({
    isNewVin: true,
    pageNameLegacy: pageName,
    formName: LEAD_FORMS_CREATIVE_ID.PRICING_BOX_FORM,
  });

  return (
    <Fragment>
      <div
        className={classnames('preprod-pricing-button', className)}
        data-tracking-parent="edm-entry-preprod_core-pricing-box"
      >
        <div className={copyClassName}>{LEAD_FORM_COPY}</div>
        <Button
          color="blue-50"
          size="lg"
          className={classnames('pricing-btn text-white text-transform-none', btnClassName, { 'mb-1': !!hasSrpLink })}
          onClick={toggleDrawerOpened}
          data-tracking-id="view_pricing_details"
          data-hidedelightedoninteraction
        >
          Contact local dealers
        </Button>
        {srpLinkComponent}
      </div>

      <TransparentPricingLeadFormDrawer
        isOpen={isDrawerOpened}
        onDrawerToggle={toggleDrawerOpened}
        params={{ styleId: mostPopularStyleId, vehicle, zipCode }}
        options={{
          leadFormComponentConfig: LEAD_FORM_CONFIGS.QUICK_QUOTES_CPL,
          isMobile,
          creativeId: dataTracking,
          headingText: `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name}`,
          explainingTextDesktop: LEAD_FORM_COPY,
          explainingTextMobile: LEAD_FORM_COPY,
          thankYouComponent: THANK_YOU_VIEWS.TRANSPARENT_PRICING_PREPROD,
          thankYouHeadingText: 'Success! You’re all set.',
          thankYouExplainingText: null,
          thankYouPriceValue: get(firstContent, 'price.value', {}),
          processDataMethods: { getLeadSubmissionInfo: getLeadSubmissionInfoWithComments },
          initialCollapseState: COLLAPSE_STATE.PARTIAL_COLLAPSE,
        }}
        creativeId={dataTracking}
      />
    </Fragment>
  );
}

PreprodPricingButtonUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  firstContent: FirstContentEntity,
  mostPopularStyleId: PropTypes.number,
  leadFormDealerCount: PropTypes.number,
  className: PropTypes.string,
  copyClassName: PropTypes.string,
  btnClassName: PropTypes.string,
  zipCode: PropTypes.string,
  isMobile: PropTypes.bool,
  pageName: PropTypes.string,
  hasSrpLink: PropTypes.bool,
};

PreprodPricingButtonUI.defaultProps = {
  firstContent: null,
  mostPopularStyleId: null,
  leadFormDealerCount: null,
  className: null,
  copyClassName: null,
  btnClassName: null,
  zipCode: null,
  isMobile: false,
  pageName: '',
  hasSrpLink: false,
};

export const mapStateToProps = state => ({
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
});

export const propsAreEqual = (prevProps, { leadFormDealerCount }) => leadFormDealerCount === null;
export const PreprodPricingButton = connect(mapStateToProps)(
  connectToModel(React.memo(PreprodPricingButtonUI, propsAreEqual), {
    zipCode: bindToPath('location.zipCode', VisitorModel),
    mostPopularStyleId: bindToPath(
      ({ vehicle }) => buildPopularStylesPath(getParamsFromVehicle(vehicle)),
      VehicleModel,
      styles => get(styles, '[0].id')
    ),
    leadFormDealerCount: bindToPath(
      ({ vehicle }) =>
        getUnifiedLeadFormDealerCountV2Path({
          makeSlug: vehicle.make.slug,
          modelSlug: vehicle.model.slug,
          strategy: LEAD_FORM_CONFIGS.QUICK_QUOTES_CPL.strategy,
        }),
      UnifiedLeadFormModel
    ),
    firstContent: bindToPath(
      ({ vehicle }) => buildPreProdContentPath(getParamsFromVehicle(vehicle)),
      EditorialReviewModel
    ),
  })
);
