import { TrackingEvent } from 'client/tracking/event';
import { TrackingHelper } from 'client/tracking/maps/helper';
import { TrackingConstant } from 'client/tracking/constant';

function getOverrides(options) {
  return TrackingHelper.getBasicDealerOptions(options);
}

export const DealerMap = {
  dealer_engagement_chat(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_DEALER_ENGAGEMENT,
      TrackingConstant.CHAT_DEALER,
      getOverrides(options)
    );
  },

  dealer_engagement_view_details(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_DEALER_ENGAGEMENT,
      TrackingConstant.VIEW_DEALER_DETAILS,
      getOverrides(options)
    );
  },

  dealer_engagement_view_reviews(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_DEALER_ENGAGEMENT,
      TrackingConstant.VIEW_DEALER_REVIEWS,
      getOverrides(options)
    );
  },

  dealer_engagement_view_inventory(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_DEALER_ENGAGEMENT,
      TrackingConstant.VIEW_DEALER_INVENTORY,
      getOverrides(options)
    );
  },

  dealer_engagement_call(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_DEALER_ENGAGEMENT,
      TrackingConstant.CALL_DEALER,
      getOverrides(options)
    );
  },

  dealer_engagement_text(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_DEALER_ENGAGEMENT,
      TrackingConstant.TEXT_DEALER,
      getOverrides(options)
    );
  },

  dealer_engagement_view_comments(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_DEALER_ENGAGEMENT,
      TrackingConstant.VIEW_DEALER_COMMENTS,
      getOverrides(options)
    );
  },

  dealer_engagement_go_to_dealer_site(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_DEALER_ENGAGEMENT,
      TrackingConstant.EDMUNDS_DEALER_SITE_CLICKOUT,
      getOverrides(options)
    );
  },

  dealer_view_pricing_details(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
      TrackingConstant.VIEW_PRICING_DETAILS,
      getOverrides(options)
    );
  },
};
