export const CREATIVE_ID = {
  USED_SINGLE_VDP_EMBEDDED_PRICING: 'uci_single_vdp_embedded_pricing',
  NEW_MULTI_CORE: 'nci_multi_core',
  NEW_MULTI_PRICE_CHECKER: 'new-price_checker',
  NEW_EMBEDDED_PRICE_CHECKER: 'new-embedded-price_checker',
  QUICK_QUOTES_BASE: 'dl_qq_base',
  CONFIGURATOR: 'dl_configurator',
  NCI_ELF_PRICING_BUILD: 'nci_elf_pricing_build',
  TED_ENTRY_LEASE: 'ted_entry_lease',
  TED_ENTRY_LOAN: 'ted_entry_loan',
  TED_ENTRY_USED_LOAN: 'ted_entry_used_loan',
};

export const LEAD_FORMS_CREATIVE_ID = {
  DEALER_FORM_SRP: 'dealer',
  MULTI_BASE_SRP_FORM: 'srp_form',
  SINGLE_BASE_VDP_FORM: 'vdp_form',
  SINGLE_VDP_EMBEDDED_FORM: 'embedded_form',
  SINGLE_VDP_PRICING_EMBEDDED_FORM: 'embedded_pricing_form',
  LOW_INVENTORY_NCI_SRP: 'low_inventory_embedded_form',
  DEALS_FORM: 'deals_form',
  PRICING_CARDS_FORM: 'pricing_cards',
  QUICK_QUOTES_BASE: 'quick_quotes',
  QUICK_QUOTES_JLR: 'jlr_quick_quotes',
  PAID_LANDING: 'paid_landing',
  FOOTER_PRICING_CARDS_FORM: 'footer_pricing_cards',
  DEAL_CHECK_PRICE_SINGLE_PAGE: 'deal_check_price_single_page',
  DEAL_CHECK_PRICE_SINGLE_EMBEDDED: 'deal_check_price_single_embedded',
  DEAL_CHECK_PRICE_MULTI_DRAWER: 'deal_check_price_multi_drawer',
  PRICING_BOX_FORM: 'pricing_box',
  TRIM_SUMMARY_FORM: 'trim_summary',
  FOOTER_PRICING_MODULE: 'footer_pricing_module',
  FEATURE_SPECS: 'feature_specs',
  FOOTER_EMBEDDED_FORM: 'footer_embedded_form',
  FOOTER_EMBEDDED_FORM_STEP_4: 'footer_embedded_form_step_4',
  PRICE_CHECKER_PAGE_FORM: 'get_quotes',
  PANEL_EMAIL_FORM: 'panel_email',
  VDP_PHOTOFLIPPER_FORM: 'vdp_photoflipper',
  CHECK_AVAILABILITY: 'check_availability',
};
