import PropTypes from 'prop-types';
import { isNumber, get } from 'lodash';

import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { logger } from 'client/utils/isomorphic-logger';

import { VisitorModel } from './visitor';

export const ANY_COLOR = {
  name: 'Any Color',
  properties: {
    rgb: 'any',
  },
};

function aggregatedInfoTransform(data) {
  const dealers = get(data, 'dealerInfo.franchiseId', {});
  const vehicleExterior = get(data, 'vehicleInfo.vehicleColors.exterior.nameRGB', {});
  const exteriorColors = Object.keys(vehicleExterior).map(key => {
    const [name, rgb] = key.split('|');
    return {
      name,
      properties: {
        rgb,
      },
    };
  });
  return {
    vehicleColors: {
      exterior: [ANY_COLOR, ...exteriorColors],
    },
    dealers,
  };
}

export const VehicleColors = PropTypes.shape({
  exterior: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      properties: PropTypes.shape({
        rgb: PropTypes.string,
      }),
    })
  ),
});

export const AggregatedInfo = PropTypes.shape({
  vehicleColors: VehicleColors,
  dealers: PropTypes.objectOf(PropTypes.number),
});

export function getDealerFranchiseIds(leadTargets) {
  return leadTargets
    .filter(({ inventory }) => inventory)
    .map(leadTarget => get(leadTarget, 'inventory.dealerInfo.franchiseId'))
    .join();
}

export function getUnifiedLeadFormDealerCountV2Path({ makeSlug, modelSlug, strategy }) {
  return (
    !!makeSlug &&
    !!modelSlug &&
    !!strategy &&
    `makes["${makeSlug}"].models["${modelSlug}"].strategy["${strategy}"].dealerCount`
  );
}

export function getPathAggregatedInfoByStyleDealers(styleId, franchiseIds) {
  return styleId && franchiseIds && `styleIds["${styleId}"].dealers["${franchiseIds}"].aggregatedInfo`;
}

export function getPathInventoriesByStyleDealersColor(styleId, franchiseIds, color) {
  return styleId && franchiseIds && `styleIds["${styleId}"].dealers["${franchiseIds}"].color["${color}"].inventories`;
}

export function getPathAggregatedInfoByTrimDealers(model, trim, franchiseIds) {
  return (
    model && trim && franchiseIds && `models["${model}"].trims["${trim}"].dealers["${franchiseIds}"].aggregatedInfo`
  );
}

export function getPathInventoriesByTrimDealersColor(model, trim, franchiseIds, color) {
  return (
    model &&
    trim &&
    franchiseIds &&
    `models["${model}"].trims["${trim}"].dealers["${franchiseIds}"].color["${color}"].inventories`
  );
}

export const UnifiedLeadFormModel = createModelSegment('unifiedLeadForm', [
  /**
   * @see getUnifiedLeadFormDealerCountV2Path
   */
  {
    path: 'makes["{make}"].models["{model}"].strategy["{strategy}"].dealerCount',
    async resolve({ make, model, strategy }, context) {
      const defaultDealerCount = 0;

      return context.resolveValue('location', VisitorModel).then(({ zipCode, latitude, longitude }) => {
        const url = `/lead-business/v2/dealercount?zip=${zipCode}&lat=${latitude}&lon=${longitude}&make=${make}&model=${model}&radius=100&strategy=${strategy}`;
        return withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(response => (response && isNumber(response.resultSize) ? response.resultSize : defaultDealerCount))
          .catch(e => {
            logger('error', `Not able to get dealer count. Error - ${e}`);
            return defaultDealerCount;
          });
      });
    },
  },
  /**
   * @see getPathAggregatedInfoByStyleDealers
   */
  {
    path: 'styleIds["{styleId}"].dealers["{franchiseIds}"].aggregatedInfo',
    async resolve({ styleId, franchiseIds }, context) {
      const url = `/inventory/v5/aggregate?dealerInfo.franchiseId=${franchiseIds}&vehicleInfo.styleInfo.styleId=${styleId}&dealerInfo.productFeatures.directDealer=true&by=vehicleInfo.vehicleColors.exterior.nameRGB,dealerInfo.franchiseId`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(aggregatedInfoTransform);
    },
  },
  /**
   * @see getPathInventoriesByStyleDealersColor
   */
  {
    path: 'styleIds["{styleId}"].dealers["{franchiseIds}"].color["{color}"].inventories',
    async resolve({ styleId, franchiseIds, color }, context) {
      return context.resolveValue('location', VisitorModel).then(({ zipCode, dma, latitude, longitude }) => {
        const url = `/inventory/v5/onePerDealer?dealerInfo.franchiseId=${franchiseIds}&vehicleInfo.styleInfo.styleId=${styleId}&vehicleInfo.vehicleColors.exterior.name=${color}&zip=${zipCode}&lat=${latitude}&lon=${longitude}&userDma=${dma}&dealerInfo.productFeatures.directDealer=true&pagenum=1&pagesize=3&radius=6000&deliveryType=all&view=DEFAULT_SRP&sortby=_geo:desc&fields=stockNumber,vin,dealerInfo.franchiseId,vehicleInfo.vehicleColors.exterior(name,r,g,b),prices(totalMsrp,tmv),computedInfo.bestDealPrice`;
        return withMetrics(EdmundsAPI, context).fetchJson(url);
      });
    },
  },
  /**
   * @see getPathAggregatedInfoByTrimDealers
   */
  {
    path: 'models["{model}"].trims["{trim}"].dealers["{franchiseIds}"].aggregatedInfo',
    async resolve({ model, trim, franchiseIds }, context) {
      const url = `/inventory/v5/aggregate?dealerInfo.franchiseId=${franchiseIds}&vehicleInfo.styleInfo.model=${model}&vehicleInfo.styleInfo.trim=${trim}&dealerInfo.productFeatures.directDealer=true&by=vehicleInfo.vehicleColors.exterior.nameRGB,dealerInfo.franchiseId`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(aggregatedInfoTransform);
    },
  },
  /**
   * @see getPathInventoriesByTrimDealersColor
   */
  {
    path: 'models["{model}"].trims["{trim}"].dealers["{franchiseIds}"].color["{color}"].inventories',
    async resolve({ model, trim, franchiseIds, color }, context) {
      return context.resolveValue('location', VisitorModel).then(({ zipCode, dma, latitude, longitude }) => {
        const url = `/inventory/v5/onePerDealer?dealerInfo.franchiseId=${franchiseIds}&vehicleInfo.styleInfo.model=${model}&vehicleInfo.styleInfo.trim=${trim}&vehicleInfo.vehicleColors.exterior.name=${color}&zip=${zipCode}&lat=${latitude}&lon=${longitude}&userDma=${dma}&dealerInfo.productFeatures.directDealer=true&pagenum=1&pagesize=3&radius=6000&deliveryType=all&view=DEFAULT_SRP&sortby=_geo:desc&fields=stockNumber,vin,dealerInfo.franchiseId,vehicleInfo.vehicleColors.exterior(name,r,g,b),vehicleInfo.photo.defaultPhoto.large,prices(displayPrice,guaranteedPrice,bestDealPrice),computedInfo.bestDealPrice`;
        return withMetrics(EdmundsAPI, context).fetchJson(url);
      });
    },
  },
]);
