import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, uniq, without } from 'lodash';
import debounce from 'lodash/debounce';
import { SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { ScrollLink } from 'site-modules/shared/components/scroll-link/scroll-link';
import { Link } from 'site-modules/shared/components/link/link';

import './anchor-nav-content.scss';

const ARROW_CONTAINER_CLASS_NAME = 'arrow pos-a top-0 bottom-0 align-items-center';

export function AnchorNavContent({
  isMobile,
  navList,
  linkTrackingId,
  ariaLabel,
  className,
  listClassName,
  itemClassName,
  linkClassName,
  subLinkClassName,
  arrowClassName,
  subArrowClassName,
  activeClass,
  creativeId,
  addTitleAttr,
  hideArrowBtns,
  noScroll,
  useItemClassName,
  useNoFollow,
}) {
  const navBarRef = useRef();
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);
  const [activeHash, setActiveHash] = useState(null);
  const activeItems = useRef([]);

  const navListLength = navList?.length;
  const linkRefs = useMemo(() => {
    const refs = {};

    navList?.forEach?.(({ hash }) => {
      refs[hash] = React.createRef();
    });

    return refs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navListLength]);

  const setArrows = useCallback(
    debounce(() => {
      const navBar = navBarRef.current;

      setLeftArrow(!!navBar.scrollLeft);
      setRightArrow(navBar.scrollWidth - navBar.clientWidth - navBar.scrollLeft > 1);
    }, 50),
    []
  );

  useEffect(() => {
    // A bit more delay on page load so sticky part is available
    setTimeout(setArrows, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setArrows);

    return () => {
      window.removeEventListener('resize', setArrows);
    };
  }, [setArrows]);

  const onDragStart = useCallback(startEvent => {
    const eventData = get(startEvent, 'touches[0]', startEvent);
    const startX = eventData.pageX - navBarRef.current.offsetLeft;
    const startScrollLeft = navBarRef.current.scrollLeft;

    function onDragMove(moveEvent) {
      moveEvent.preventDefault();
      const moveEventData = get(moveEvent, 'touches[0]', moveEvent);
      const walk = moveEventData.pageX - navBarRef.current.offsetLeft - startX;
      navBarRef.current.scrollLeft = startScrollLeft - walk;
    }

    // debounce used here to make sure click event on child links is fired before this.
    // fixes issue when touchend fires before and blocks click
    const removeListeners = debounce(removeEvent => {
      removeEvent.preventDefault();
      navBarRef.current.removeEventListener('mousemove', onDragMove);
      navBarRef.current.removeEventListener('touchmove', onDragMove);
      navBarRef.current.removeEventListener('mouseleave', removeListeners);
      navBarRef.current.removeEventListener('mouseup', removeListeners);
      navBarRef.current.removeEventListener('touchend', removeListeners);
    }, 0);

    navBarRef.current.addEventListener('mousemove', onDragMove);
    navBarRef.current.addEventListener('touchmove', onDragMove);
    navBarRef.current.addEventListener('mouseleave', removeListeners);
    navBarRef.current.addEventListener('mouseup', removeListeners);
    navBarRef.current.addEventListener('touchend', removeListeners);
  }, []);

  /**
   * Calculates and makes link centered every time it's chosen as active
   * @param currentHash ID of a section
   */
  const scrollToCurrent = useCallback(
    debounce(currentHash => {
      const navBar = navBarRef.current;
      const currentLink = get(linkRefs, `["${currentHash}"].current`);

      const centerStart = navBar.clientWidth / 2 - currentLink.clientWidth / 2;
      navBarRef.current.scrollLeft = Math.max(0, currentLink.offsetLeft - centerStart);
    }, 150),
    [linkRefs]
  );

  const handleSetActive = useCallback(
    newActiveHash => {
      setActiveHash(newActiveHash);
      activeItems.current = uniq([...activeItems.current, newActiveHash]);

      scrollToCurrent(newActiveHash);
    },
    [scrollToCurrent]
  );

  const handleSetInactive = useCallback(newInactiveHash => {
    if (newInactiveHash === activeItems.current[activeItems.current.length - 1]) {
      setActiveHash(activeItems.current[activeItems.current.length - 2]);
    }
    activeItems.current = without(activeItems.current, newInactiveHash);
  }, []);

  function scrollToStart() {
    navBarRef.current.scrollLeft = 0;
  }

  function scrollToEnd() {
    navBarRef.current.scrollLeft = navBarRef.current.scrollWidth - navBarRef.current.clientWidth;
  }

  return (
    <div className={classnames('anchor-nav-content pos-r no-overflow', className)} data-tracking-parent={creativeId}>
      <div
        className={classnames(ARROW_CONTAINER_CLASS_NAME, subArrowClassName, 'left left-0', {
          'd-flex': leftArrow,
          'd-none': !leftArrow,
        })}
      >
        {!hideArrowBtns && (
          <button
            className={classnames(arrowClassName, 'icon-arrow-left3 pl-0_25')}
            onClick={scrollToStart}
            aria-label="More Links"
          />
        )}
      </div>
      <nav
        className={classnames('navbar p-0 text-nowrap heading-6 fw-normal', {
          'no-overflow': !isMobile,
          'fade-left': leftArrow,
          'fade-right': rightArrow,
        })}
        ref={navBarRef}
        onMouseDown={isMobile ? undefined : onDragStart}
        onTouchStart={isMobile ? undefined : onDragStart}
        onScroll={setArrows}
        aria-label={ariaLabel}
      >
        <ul
          className={classnames('list-unstyled text-start mb-0', listClassName, {
            'ml-0': !useItemClassName,
          })}
          data-tracking-id={linkTrackingId}
        >
          {navList.map(({ name, hash, customOffset }, index) => (
            <li
              key={name}
              className={classnames('nav-item d-inline-block', useItemClassName && itemClassName, {
                'pl-0 size-16': index === 0 && !useItemClassName,
                'pl-2 size-16': index > 0 && !useItemClassName,
              })}
              ref={linkRefs[hash]}
            >
              {noScroll ? (
                <Link
                  to={`${hash}/`}
                  rel={useNoFollow ? 'nofollow' : null}
                  className={classnames('d-inline-block text-nowrap sub-navigation-link', subLinkClassName)}
                  data-tracking-value={name}
                  title={name}
                >
                  {name}
                </Link>
              ) : (
                <ScrollLink
                  spy
                  to={hash}
                  scrollConfig={{ offset: customOffset || SCROLL_OFFSET }}
                  className={classnames('d-inline-block text-nowrap sub-navigation-link', linkClassName, {
                    [activeClass]: hash === activeHash,
                  })}
                  data-tracking-value={name}
                  onSetActive={handleSetActive}
                  onSetInactive={handleSetInactive}
                  rel="nofollow"
                  title={addTitleAttr ? name : undefined}
                >
                  {name}
                </ScrollLink>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div
        className={classnames(ARROW_CONTAINER_CLASS_NAME, subArrowClassName, 'right right-0 justify-content-end', {
          'd-flex': rightArrow,
          'd-none': !rightArrow,
        })}
      >
        {!hideArrowBtns && (
          <button
            className={classnames(arrowClassName, 'icon-arrow-right3 pr-0_25')}
            onClick={scrollToEnd}
            aria-label="More Links"
          />
        )}
      </div>
    </div>
  );
}

AnchorNavContent.propTypes = {
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hash: PropTypes.string,
    })
  ).isRequired,
  linkTrackingId: PropTypes.string,
  isMobile: PropTypes.bool,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  listClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  subLinkClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  subArrowClassName: PropTypes.string,
  activeClass: PropTypes.string,
  creativeId: PropTypes.string,
  addTitleAttr: PropTypes.bool,
  hideArrowBtns: PropTypes.bool,
  noScroll: PropTypes.bool,
  useItemClassName: PropTypes.bool,
  useNoFollow: PropTypes.bool,
};

AnchorNavContent.defaultProps = {
  linkTrackingId: 'view_content',
  isMobile: false,
  ariaLabel: null,
  className: null,
  listClassName: null,
  itemClassName: 'pl-2 size-16',
  linkClassName: 'pt-1 pb-0_75',
  arrowClassName: 'icon btn-link border-0 text-decoration-none text-primary-darker h-100 px-0_5 px-md-0_75',
  subLinkClassName: 'pt-1 pb-0_75',
  subArrowClassName: null,
  activeClass: 'link-active text-primary-darker',
  creativeId: null,
  addTitleAttr: false,
  hideArrowBtns: false,
  noScroll: false,
  useItemClassName: true,
  useNoFollow: true,
};
